<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <button :class="$style.create" @click="isShow = true">
      <Icon role="button" name="plus" :class="$style.plus" /> Cоздать раздел
    </button>
    <form v-if="isShow" :class="$style.form" @submit.prevent="submitForm">
      <Icon name="plus" :class="$style.plus" @click="isShow = false"></Icon>
      <h2>Создать раздел</h2>
      <Input
        v-model="input"
        :class="$style.input"
        placeholder="Название раздела"
      />
      <Button type="primary">Создать </Button>
      <div v-if="error" :class="$style.error">
        {{ error }}
      </div>
    </form>

    <div :class="$style.responsive">
      <table :class="$style.tabel">
        <thead :class="$style.header">
          <tr :class="$style.row">
            <th :class="[$style.draggable, $style.nograb]"></th>
            <th :class="$style.name">Название раздела</th>
            <th :class="$style.section">Перейти к разделу</th>
            <th :class="$style.delete">Удалить</th>
          </tr>
        </thead>
        <Container
          tag="tbody"
          :class="$style.body"
          @drop="onDrop"
          @drag-start="drag = false"
          @drag-end="drag = true"
        >
          <Draggable
            tag="tr"
            :class="$style.row"
            v-for="item in list"
            :key="item.id"
          >
            <td :class="$style.draggable">
              <Icon name="draggable" :class="$style.icon" />
            </td>
            <td :class="$style.name">
              <form ref="name" @submit.prevent="changeName(item.id)">
                <input :id="item.id" :value="item.name" />
                <button type="submit">Изменить</button>
              </form>
            </td>
            <td :class="$style.section">
              <router-link :to="`downloads/${item.id}`">
                Перейти к разделу
              </router-link>
            </td>
            <td :class="$style.delete">
              <Icon
                role="button"
                name="plus"
                :class="$style.deleteIcon"
                @click="deleteGroup(item.id)"
              />
            </td>
          </Draggable>
        </Container>
      </table>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import Input from '@/components/atoms/Input.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Button from '@/components/atoms/Button.vue'
import delivery from '@/delivery'

export default {
  components: { Icon, Input, Button, Loader },
  mounted() {
    this.getDownloads()
  },
  data() {
    return {
      list: null,
      input: '',
      isShow: false,
      error: '',
      debounceTimeout: null,
      drag: false,
      isLoader: false,
    }
  },
  watch: {
    async drag() {
      if (this.drag) {
        await this.debounce(() => this.updateOrders(), 2000)
      }
    },
  },
  methods: {
    async getDownloads() {
      const { value, error } =
        await delivery.AddwineCore.DownloadsActions.getList()
      if (error) return
      this.list = value
    },
    clearForm() {
      this.input = ''
    },
    async submitForm() {
      if (!this.input) {
        this.error = 'Заполните это поле'
        return
      }
      this.error = ''

      const result = await delivery.AddwineCore.DownloadsActions.createGroup({
        name: this.input,
      })
      if (result.error) {
        console.error(result.error)
        alert('Ошибка добавления файла!')
      } else {
        this.clearForm()
        this.getDownloads()
      }
    },
    async deleteGroup(id) {
      const isConfirm = confirm(
        'При удалении раздела все файлы в этом разделе будут удалены. Вы уверены, что хотите удалить раздел?',
      )
      if (isConfirm) {
        const result = await delivery.AddwineCore.DownloadsActions.deleteGroup(
          id,
        )
        this.isLoader = true
        if (result.error) {
          console.error(result.error)
          alert('Ошибка удаления группы!')
        } else {
          this.getDownloads()
        }
        this.isLoader = false
      }
    },
    async changeName(id) {
      this.isLoader = true
      const name = document.getElementById(id).value
      const result =
        await delivery.AddwineCore.DownloadsActions.changeGroupName(id, {
          name,
        })
      if (result.error) {
        console.error(result.error)
        alert('Ошибка изменения имени группы файлов!')
      } else {
        this.getDownloads()
      }
      this.isLoader = false
    },
    debounce(callback, ms) {
      return ((...args) => {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          callback(...args)
        }, ms)
      })()
    },
    async updateOrders() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].order = i + 1
      }
      const result = await delivery.AddwineCore.DownloadsActions.updateOrders({
        groups: this.list,
      })
      if (result.error) return
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
      this.list = this.applyDrag(this.list, dropResult)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  overflow: hidden;
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin: 2rem 0;
    cursor: pointer;
    gap: 0.5rem;
    text-decoration: none;
    color: $black-gray;
    &:hover {
      background: $white;
    }
  }
  .form {
    background: $white;
    padding: 2rem;
    max-width: 30rem;
    margin-bottom: 3rem;
    position: relative;
    h2 {
      color: $black-gray;
      margin-bottom: 1.5rem;
    }
    .input {
      width: 100%;
      margin-bottom: 1.5rem;
    }
    .plus {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      transform: rotate(45deg);
    }
  }
  h2 {
    padding: 1rem 0;
  }
  .plus {
    fill: $black-gray;
    width: 1rem;
    height: 1rem;
  }

  .responsive {
    border-radius: 0.25rem;
    @include tabelShadow;
    width: 100%;
    overflow-x: auto;
    .tabel {
      width: 100%;
      table-layout: auto;
      .header {
        th {
          color: $tabel-th-color;
          font-weight: 700;
          text-align: left;
        }
      }
      .row {
        display: block;
        padding: 0.75rem;
        border-bottom: 1px solid $tabel-border;
        &:nth-child(odd) {
          background-color: $bgc-tabel-row;
        }
        .draggable {
          min-width: 3rem;
          cursor: move;
          &.nograb {
            cursor: default;
          }
          .icon {
            width: 1rem;
            height: 1rem;
          }
        }
        .name {
          min-width: 25rem;
        }
        .section {
          min-width: 12.75rem;
        }
        .delete {
          min-width: 4rem;
          .deleteIcon {
            cursor: pointer;
            fill: $black-gray;
            width: 1rem;
            height: 1rem;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>
